import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css'],
    imports: [MatButtonModule, MatDialogModule, CommonModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: 
  { 
    title: string; 
    content: string, 
    showTitle: boolean
  }) {}
  
  readonly dialogRef = inject(MatDialogRef<DialogComponent>);
}
