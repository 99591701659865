import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';

import { ApiServiceService, SearchResult } from '../../services/api-service.service';
import { SearchQuery, type OrderBy } from '../../search-query';
import { SearchItem } from '../../search-item';
import { PaginationData } from 'src/app/pagination-data';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';



@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.css'],
    standalone: false
})
export class SearchComponent implements OnInit {
  displayedColumns: string[] = ['ClaimNo', 'MedicareId', 'LastName', 'FirstName', 'DateOfEntitlement'];
  constructor(
    private apiService: ApiServiceService, 
    private route: ActivatedRoute, 
    private router: Router, 
    private location: Location,
    private datePipe: DatePipe) {

  }
  // @ViewChild('paginatorPageSize') paginatorPageSize: MatPaginator;
  @ViewChild('paginatorPageSize', { static: true }) paginatorPageSize!: MatPaginator;
  // @ViewChild(MatSort) sort!: MatSort;
  
  results: SearchItem[] = [];
  dataSource = new MatTableDataSource(this.results);
  resultCount: number = 0;
  pageSizes = [10, 25, 100];
  pageIndex = 0;
  pageSize = 10;

  /**
   * Hide results initially so we don't display the no results message before a search is made.
   */
  resultVisibility: boolean = false;

  /**
   * Hide or show loading spinner instead of results.
   */
  loading: boolean = false;

  /**
   * Sets the initial state of the form when loading a search from the url
   */
  searchParams: SearchQuery = new SearchQuery();

  paginationData: PaginationData = new PaginationData();

  // columnInfo = SearchItem.ResultColumns;

  ngOnInit(): void {
    this.loadParamState(this.route.snapshot.queryParamMap);
    // reload params on popState event (browser history buttons)
    this.location.subscribe((event) => {
      let paramString = event.url?.substring(event.url.indexOf('?'));
      let params = new URLSearchParams(paramString);
      this.loadParamState(params);
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginatorPageSize;
  }

  /**
   * Loads the saved search from the provided query parameters into the model.
   * If there is a saved search, then it will automatically load the search results onto the page.
   * @param params The query parameters to load the data from. A map.
   */
  loadParamState(params) {
    // let params = this.route.snapshot.queryParamMap;
    this.searchParams.claimNumber = params.get('claimNumber') || '';
    this.searchParams.mbi = params.get('mbi') || '';
    this.searchParams.lastName = params.get('lastName') || '';
    this.searchParams.firstName = params.get('firstName') || '';
    this.searchParams.dateOfBirth = params.get('dateOfBirth') || '';
    this.searchParams.orderBy = params.get('orderBy') as OrderBy || 'claimno';
    let orderDir = params.get('orderDir') as 'ASC' | 'DESC';
    if (typeof orderDir == undefined || orderDir !== 'ASC' && orderDir !== 'DESC') orderDir = 'ASC'
    this.searchParams.orderDir = orderDir;
    this.paginationData.skip = Number.parseInt(params.get('skip') || '0', 10);

    let hasSavedSearch = ['claimNumber', 'mbi', 'lastName', 'firstName', 'dateOfBirth'].some((paramName) => params.has(paramName));

    if (hasSavedSearch) {
      this.resultVisibility = true;
      this.loadSearchResults(this.searchParams);
    } else {
      this.resultVisibility = false;
    }
  }

  // navigateWithQuery(queryParams: any) {
  //   return this.router.navigate([], {
  //     relativeTo: this.route,
  //     queryParams,
  //   });
  // }

  /**
   * Event handler for submission of the search form. It resets the pagination and sorting parameters and calls the search.
   * It also shows the results element by changing resultVisibility to true.
   * @param _queryData The current state of the search form when the request is submitted.
   */
  onSearch(_queryData: SearchQuery, callback: (response: SearchResult) => void) {
    this.resultVisibility = true;
    this.paginationData.skip = 0;
    this.searchParams.orderBy = 'claimno';
    this.searchParams.orderDir = 'ASC';

    // this.search();
    this.search(_queryData, callback);
  }

  onExport(_queryData: SearchQuery, callback: (response: SearchResult) => void) {
    this.export(_queryData, callback);
  }

  /**
   * Saves the current search parameters in the query string of the url and then triggers a search.
   */
  search(queryData: SearchQuery, callback: (response: SearchResult) => void) {
    // this.navigateWithQuery(this.searchParams);
    // this.loadSearchResults(this.searchParams);
    this.loadSearchResultsWithCallback(queryData, callback);
  }
  
  export(queryData: SearchQuery, callback: (response: SearchResult) => void) {
    this.fetchDataToExport(queryData, callback);
  }

  fetchDataToExport(queryData: SearchQuery, callback: (response: SearchResult) => void) {
    this.loading = true;
    queryData.dob = this.datePipe.transform(this.searchParams.dateOfBirth,"yyyyMMdd") || '';
    this.apiService.export(queryData, this.paginationData).subscribe(
      (data) => {
        var response = <SearchResult>data;
        this.loading = false;
        callback(response);
    });
  }

    /**
   * Sends a search query to the api with the given data and the saved pagination settings,
   * then loads the results onto the page. While the request is processing, it sets loading to be true,
   * which shows a loading spinner, and hides the results list with visibility: hidden, to prevent large changes
   * in the page height.
   * @param queryData The search parameters to make the search with.
   */
    loadSearchResultsWithCallback(queryData: SearchQuery, callback: (response: SearchResult) => void) {
      this.loading = true;
      queryData.dob = this.datePipe.transform(this.searchParams.dateOfBirth,"yyyyMMdd") || '';
      this.apiService.search(queryData, this.paginationData).subscribe(
        (data) => {
          var response = <SearchResult>data;
          this.resultCount = response.TotalRecords;
          this.paginationData.totalCount = response.TotalRecords;
          this.results = response.PaidActData;
          this.dataSource = new MatTableDataSource(this.results);
          // this.dataSource.sort = this.sort;
          this.loading = false;
  
          callback(response);
      });
    }

    /**
   * Sends a search query to the api with the given data and the saved pagination settings,
   * then loads the results onto the page. While the request is processing, it sets loading to be true,
   * which shows a loading spinner, and hides the results list with visibility: hidden, to prevent large changes
   * in the page height.
   * @param queryData The search parameters to make the search with.
   */
  loadSearchResults(queryData: SearchQuery) {
    this.loading = true;
    queryData.dob = this.datePipe.transform(this.searchParams.dateOfBirth,"yyyyMMdd") || '';
    this.apiService.search(queryData, this.paginationData).subscribe(
      (data) => {
        var response = <SearchResult>data;
        this.resultCount = response.TotalRecords;
        this.paginationData.totalCount = response.TotalRecords;
        this.results = response.PaidActData;
        this.dataSource = new MatTableDataSource(this.results);
        // this.dataSource.sort = this.sort;
        this.loading = false;
    });
  }

  onEdit(data: SearchItem) {
    this.router.navigate(['summary/' + data.RecordId]);
  }

  /**
   * Event handler for clicking on the number buttons or the next and previous buttons.
   * @param newSkip The new number of records to skip past.
   */
  onPageNav(newSkip: number, pageSize: number) {
    this.paginationData.skip = newSkip;
    this.paginationData.count = pageSize;
    this.loadSearchResults(this.searchParams);
  }

  onPageChange(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.onPageNav(this.pageIndex * this.pageSize, this.pageSize);
  }

  SortChange(sortState: Sort) {
    this.paginationData.orderBy = sortState.active;
    this.paginationData.sortDirection = sortState.direction;  
    this.onPageNav(this.pageIndex * this.pageSize, this.pageSize);
  }
}
