import { Component } from '@angular/core';

@Component({
    selector: 'app-navigator',
    templateUrl: './navigator.component.html',
    styleUrls: ['./navigator.component.css'],
    standalone: false
})
export class NavigatorComponent {
  isExpanded = false;

}
