import { Component,Inject,OnInit } from '@angular/core';
import { OktaAuthStateService ,OKTA_AUTH} from '@okta/okta-angular';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { filter, map, Observable } from 'rxjs';
import { CommonserviceService } from 'src/app/services/commonservice.service';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
    standalone: false
})
export class HeaderComponent {
  isAuthenticated!: boolean;
  username?: string;
  constructor( 
    public _oktaStateService: OktaAuthStateService, 
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth, 
    private commonservice: CommonserviceService, 
    private apiService: ApiServiceService,
    private router: Router,
  ) { }

  async ngOnInit() {
    setTimeout(async () => {
      this.isAuthenticated = await this._oktaAuth.isAuthenticated();
      if (this.isAuthenticated) {
        const userClaims = await this._oktaAuth.getUser();
        this.username = userClaims['lastName'] + ', ' + userClaims['firstName'];
      }
    }, 1500);
  }

  logout() {
    this.commonservice.logout();
  }
}
