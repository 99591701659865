import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SearchComponent } from './components/search/search.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { SummaryComponent } from './components/summary/summary.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SortArrowsComponent } from './components/sort-arrows/sort-arrows.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigatorComponent } from './components/navigator/navigator.component';
import { MaterialModule } from './material/material.module';
import { HeaderComponent } from './components/header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DatePipe } from '@angular/common';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
import { DialogComponent } from './components/dialog/dialog.component';
import { SsnMaskDirective } from './directives/ssn-mask.directive';

@NgModule({ declarations: [
        AppComponent,
        HomeComponent,
        SearchComponent,
        SearchFormComponent,
        SummaryComponent,
        SortArrowsComponent,
        NavigatorComponent,
        HeaderComponent,
        UnauthorizedComponent,
        SsnMaskDirective
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        FontAwesomeModule,
        BrowserAnimationsModule,
        MaterialModule,
        FlexLayoutModule,
        DialogComponent], providers: [DatePipe, provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
