import { Component,OnInit,Inject } from '@angular/core';
import { AuthState, OktaAuth } from '@okta/okta-auth-js';
import { ApiServiceService } from 'src/app/services/api-service.service';
import { OktaAuthStateService ,OKTA_AUTH} from '@okta/okta-angular';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
    standalone: false
})
export class HomeComponent implements OnInit{
  
  constructor( private apiService:ApiServiceService) { 

  }
   

  ngOnInit(): void {
    

  }
  submit(){

   

    var url='http://localhost:49246/mspnav-sso-api/User/GetUserDetail';
    var data='{"Email":"I90101@verisk.com","ClientCode":"DEV"}';
    this.apiService.httpPost(url,data)
    .subscribe({
      next:(response)=> console.log(response),
      error:(error)=> console.log(error)
   });
  } 
}
