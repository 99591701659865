import { Component, Input } from '@angular/core';
import { faSort, faSortAsc, faSortDesc } from '@fortawesome/free-solid-svg-icons';
import { OrderBy } from 'src/app/search-query';

@Component({
    selector: 'app-sort-arrows',
    templateUrl: './sort-arrows.component.html',
    styleUrls: ['./sort-arrows.component.css'],
    standalone: false
})
export class SortArrowsComponent {
  faSort = faSort;
  faSortAsc = faSortAsc;
  faSortDesc = faSortDesc;

  @Input({required: true}) orderBy: OrderBy = 'claimno';
  @Input({required: true}) orderDir: 'ASC' | 'DESC' = 'ASC';
  @Input({required: true}) columnType: OrderBy = '';

  get arrowMode(): ArrowMode {
    if (this.orderBy === this.columnType) {
      return this.orderDir;
    } else {
      return 'BASE';
    }
  }
}

type ArrowMode = 'BASE' | 'ASC' | 'DESC';
